'use client';

import { type FunctionComponent } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { Feature } from '@/config';
import { Routes } from '@/constants';
import { HeaderStrings as strings } from '@/translations';
import { Button, GlobalHeader, type GlobalHeaderProps } from '@motortrend/ids';
import cx from 'clsx';

import { Brand } from '@/lib/gql/graphql';
import { isAIChatbotEnabled } from '@/utils/isAIChatbotEnabled';
import { DataId } from '@/utils/nitrous/constants';
import useTheme from '@/hooks/useTheme';
import { NextLink } from '@/components/NextLink';

import { StarFilled } from '../AIChatbot/icons/StarFilled';
import { type HeaderProps } from './Header.props';

const Header: FunctionComponent<HeaderProps> = ({
  brand = Brand.Motortrend,
  menuConfig,
  sponsoredLogo,
}) => {
  const router = useRouter();
  const currentPath = usePathname();
  const { theme, toggleTheme } = useTheme();
  let hamburgerMenuConfigDetails = {};
  const isMotortrendBrand = brand === Brand.Motortrend;

  if (isMotortrendBrand) {
    hamburgerMenuConfigDetails = {
      activeTheme: theme,
      joinNewsletterLink: Routes.Newsletters,
      toggleTheme,
    };
  } else {
    hamburgerMenuConfigDetails = {
      activeTheme: theme,
      toggleTheme,
    };
  }

  const actionElement = isAIChatbotEnabled() ? (
    <Button
      colorScheme="neutral-3"
      data-id={DataId.AIChatbotGlobalHeaderButton}
      data-parent={DataId.GlobalHeader}
      ignoreTheme
      onClick={() => {
        const url = new URL(window.location.href);
        url.searchParams.set('chatbot', 'true');
        router.push(url.toString());
      }}
      ripple={false}
      size="small"
    >
      <span className="flex items-center gap-3">
        <span className="hidden @md/header:inline">
          {strings.AIChatbotButtonText}
        </span>
        <span>
          <StarFilled />
        </span>
      </span>
    </Button>
  ) : (
    isMotortrendBrand && (
      <Button
        as={NextLink}
        className="h-8 !px-2 max-lg:!rounded lg:h-auto lg:!px-4"
        data-id={DataId.GlobalHeaderActionButton}
        data-parent={DataId.GlobalHeader}
        href={Routes.Newsletters}
        typographyVariant="button2"
      >
        <span className="hidden @xs/header:inline">
          {strings.ButtonTextSpan}
        </span>
        {strings.ButtonText}
      </Button>
    )
  );

  return (
    <>
      <GlobalHeader
        activePath={currentPath}
        brand={brand as GlobalHeaderProps['brand']}
        className={cx('top-0 z-top @container/header', {
          relative: Feature.HotRodRelaunch === false,
          sticky: Feature.HotRodRelaunch === true,
        })}
        hamburgerMenuConfig={hamburgerMenuConfigDetails}
        headerActionConfig={{
          actionElement,
        }}
        linkElement={isMotortrendBrand ? NextLink : 'a'}
        menuConfig={menuConfig}
        sponsoredLogo={sponsoredLogo}
      />
    </>
  );
};

export default Header;
