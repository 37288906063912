import { z } from 'zod';

export function isAIChatbotEnabled() {
  const { NEXT_PUBLIC_AI_CHATBOT_ENABLED } = z
    .object({
      NEXT_PUBLIC_AI_CHATBOT_ENABLED: z
        .string()
        .transform((value) => value === 'true'),
    })
    .parse({
      NEXT_PUBLIC_AI_CHATBOT_ENABLED:
        process.env.NEXT_PUBLIC_AI_CHATBOT_ENABLED,
    });

  return NEXT_PUBLIC_AI_CHATBOT_ENABLED;
}
